import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import "../styles/HeroDemo.css";

export default function HeroDemo(props) {
	const [state, setState] = useState("idle");
	const handleSend = (text) => {
		setState("thinking");
		setTimeout(() => {
			setState("replied");
		}, 2000);
	};

	return (
		<section className="hero-demo">
			<div className="uppersection">
				{state !== "replied" && (
					<div className="input">
						<input
							type="text"
							placeholder={`Tell HI to ${props.influencerImage}...`}
							onKeyDown={(e) =>
								e.code === "Enter" &&
								handleSend(e.currentTarget.value)
							}
							onClick={(e) => {
								handleSend(e.currentTarget.value)
							}}
						/>
						{state === "idle" && <SendIcon className="send" />}
						{state === "thinking" && (
							<CancelScheduleSendIcon className="send" />
						)}
					</div>
				)}
				<img
					className="hero-avatar"
					src={`/Assets/${props.influencerImage}.png`}
					alt="hero-avatar"
				/>
				{state === "thinking" && (
					<div className="thinking">
						<img
							className="hero-avatar-thinking-cloud"
							src="/Assets/hero-thinking-cloud.png"
							alt="hero-avatar-thinking-cloud"
						/>
						<span>
							<img
								className="hero-avatar-thinking-cloud-dot"
								src="/Assets/hero-thinking-cloud-dot.png"
								alt="hero-avatar-thinking-cloud-dot"
							/>
							<img
								className="hero-avatar-thinking-cloud-dot"
								src="/Assets/hero-thinking-cloud-dot.png"
								alt="hero-avatar-thinking-cloud-dot"
								style={{
									animationDelay: "0.33s",
								}}
							/>
							<img
								className="hero-avatar-thinking-cloud-dot"
								src="/Assets/hero-thinking-cloud-dot.png"
								alt="hero-avatar-thinking-cloud-dot"
								style={{
									animationDelay: "0.66s",
								}}
							/>
						</span>
					</div>
				)}
				{state === "replied" && (
					<div className="thinking-complete">
						<img
							className="hero-reply"
							src="/Assets/hero-reply.png"
							alt="hero-reply"
						/>
						<p>
							As of 2023 of June, my net worth is around 22,420
							crores USD. That's pretty insane right!! <br />{" "}
							<br /> Well i am a CEO and owner of twitter. I am
							founder and CEO of space X and also a great business
							men. I invented the Paypal.
						</p>
					</div>
				)}
				{state === "replied" && (
					<div className="thinking-complete-outro">
						<h2>Want to ask more questions?</h2>
						<a href="#">Login</a>
					</div>
				)}
			</div>
			{state === "idle" && (
				<div className="example-texts">
					<div className="example-row">
						<div className="example">What is your net worth?</div>
						<div className="example">
							When are you buying reddit?
						</div>
						<div className="example">What is your portfolio?</div>
					</div>
					<div className="example-row-reverse">
						<div className="example">What is kid’s name?</div>
						<div className="example">Do you drive tesla?</div>
						<div className="example">
							When are you getting into space?
						</div>
					</div>
				</div>
			)}
		</section>
	);
}
