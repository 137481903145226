import "../styles/Milestones.css";
export default function Milestones() {
    return (
        <div className="milestones">
            <h1>Milestones</h1>
            <div className="milestones-container">
                <div className="milestone">
                    <div className="milestone-number">1200</div>
                    <div className="milestone-text">Happy Users</div>
                    <img src="/Assets/milestone-1.png" alt="milestone-1" />
                </div>
                <div className="milestone">
                    <div className="milestone-number">98</div>
                    <div className="milestone-text">Innovative Update</div>
                    <img src="/Assets/milestone-2.png" alt="milestone-2" />
                </div>
                <div className="milestone">
                    <div className="milestone-number">1000</div>
                    <div className="milestone-text">Engaging Hours</div>
                    <img src="/Assets/milestone-3.png" alt="milestone-3" />
                </div>
            </div>
        </div>
    )
}