import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import "../styles/Footer.css";

export default function Footer() {
    return(
        <footer>
            <div className="footer-links">
                <h1>Start Chatting</h1>
                <a href='#'>Login</a>
            </div>
            <div className="social-links">
                <TwitterIcon className="social-link" />
                <TelegramIcon className="social-link" />
                <InstagramIcon className="social-link" />
            </div>
        </footer>
    )
}