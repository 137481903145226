import "../styles/Hero.css";
import AnimatedNames from "./AnimatedNames";

export default function Hero(props) {
	return (
		<section className="hero">
			<img className="hero-top-left" src="/Assets/hero-top-left.png" />
			<div className="hero-content">
				<h1>
					Chat with your favourite <br />
					<AnimatedNames influencerNames={props.influencerNames} changeImage={props.changeImage}/> Influencer
				</h1>
				<img className="hero-center-center" src="/Assets/hero-center-center.png" />
			</div>
			<img className="hero-bottom-right" src="/Assets/hero-bottom-right.png" />
		</section>
	);
}
