import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { SteppedEase } from "gsap/gsap-core";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

export default function AnimatedNames(props) {
	const containerRef = useRef(null);
	const cursorRef = useRef(null);
	let influencerNamesIndex = 0;

	useEffect(() => {
		const container = containerRef.current;
		const cursor = cursorRef.current;

		if (!container || !cursor) return;

		gsap.fromTo(
			cursor,
			{ autoAlpha: 0, x: -20 },
			{
				autoAlpha: 1,
				duration: 0.5,
				repeat: -1,
				ease: SteppedEase.config(1),
			}
		);

		const animateText = (index) => {
			gsap.to(container, {
				text: {
					value: props.influencerNames[index],
				},
				onComplete: clearText,
				duration: 1,
				delay: 1,
				ease: "none",
			});
		};

		const clearText = () => {
			gsap.to(container, {
				text: {
					value: "",
					rtl: true,
				},
				onComplete: () => {
					const influencerIndex = ++influencerNamesIndex % props.influencerNames.length
					props.changeImage(influencerIndex)
					animateText(influencerIndex)
				},
				duration: 1,
				delay: 1,
				ease: "none",
				
			});
		};

		animateText(influencerNamesIndex);
	}, []);

	return (
		<span>
			<i ref={containerRef}></i>
			<span className="animated-name" ref={cursorRef}>
				|
			</span>
		</span>
	);
}
