import "../styles/Header.css";

export default function Header(){
    return(
        <header>
            <div className="logo">
                <a href="/">Influencai</a>
            </div>

            <nav>
                <ul>
                    <li className="login"><a href="#">Login</a></li>
                </ul>
            </nav>
        </header>
    )
}