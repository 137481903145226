import "./styles/root.css";
import "./styles/index.css";
import Header from "./components/Header";
import Hero from "./components/Hero";
import HeroDemo from "./components/HeroDemo";
import StartTalking from "./components/StartTalking";
import Milestones from "./components/Milestones";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import { useState } from "react";

function App() {
	const [influencerImage, setInfluencerImage] = useState("Kim kardashian");
	const influencerNames = [
		"Kim kardashian",
		"Cristiano Ronaldo",
		"Kylie Jenner",
	];

	const changeImage = (influencerIndex) => {
		setInfluencerImage(influencerNames[influencerIndex]);
	}
  return (
    <div className="App">
      <Header />
      <Hero influencerNames={influencerNames} changeImage={changeImage}/>
      <HeroDemo influencerImage={influencerImage}/>
      <StartTalking />
      <Milestones />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
