import React, { useState } from "react";
import "../styles/Testimonials.css";

export default function Testimonials() {
	const [testimonials, setTestimonials] = useState([
		{
			id: 1,
			name: "Lucas Martinez",
			message:
				"I can’t believe I actually talked to virtual Ariana Grande!",
			profileImage: "/Assets/testimonial-1.png",
		},
		{
			id: 2,
			name: "Sophia Johnson",
			message:
				"This platform brings my fan experience to a whole new level!",
			profileImage: "/Assets/testimonial-2.png",
		},
		{
			id: 3,
			name: "James Kim",
			message: "Virtual conversations have never been more exciting!",
			profileImage: "/Assets/testimonial-3.png",
		},
	]);

	return (
		<div className="testimonials">
			<div className="testimonial-container">
				{testimonials.map((testimonial) => (
					<div className="testimonial" key={testimonial.id}>
						<img src={testimonial.profileImage} alt="testimonial" />
						<div className="testimonial-text">
							{testimonial.message}
						</div>
						<div className="testimonial-name">
							{testimonial.name}
						</div>
					</div>
				))}
			</div>
			<div className="testimonial-container">
				{testimonials.map((testimonial) => (
					<div className="testimonial" key={testimonial.id}>
						<img src={testimonial.profileImage} alt="testimonial" />
						<div className="testimonial-text">
							{testimonial.message}
						</div>
						<div className="testimonial-name">
							{testimonial.name}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
