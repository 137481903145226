import SendIcon from "@mui/icons-material/Send";
import "../styles/StartTalking.css";

export default function StartTalking() {
    return(
        <div className="start-talking">
            <h1>Start Talking to</h1>
            <div className="influencerDemoList">
                <div className="elonMusk">
                    <img src="/Assets/elon-musk.png" alt="elon-musk" />
                    <input type="text" placeholder="Elon Musk" />
					<SendIcon className="send" />
                </div>
                <div className="joeBiden">
                    <img src="/Assets/joe-biden.png" alt="joe-biden" />
                    <input type="text" placeholder="Joe Biden" />
                    <SendIcon className="send" />
                </div>
                <div className="billieEilish">
                    <img src="/Assets/billie-eilish.png" alt="billie-eilish" />
                    <input type="text" placeholder="Billie Eilish" />
                    <SendIcon className="send" />
                </div>
                <p>
                    Many More...
                </p>
            </div>
        </div>
    )
}